.ant-tabs {
  color: white;
  .ant-tabs-ink-bar {
    left: 0;
    background-color: white;
  }
  .ant-tabs-tab-btn {
    color: #ffffff83;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background: #ffffff29;
    .ant-tabs-tab-btn {
      color: #ffffff;
      text-shadow: 0 0 0.25px currentcolor;
    }
  }
  .ant-tabs-tabpane {
    padding-left: 11% !important;
  }
  .ant-tabs-nav {
    flex-basis: 25% !important;
  }
  .ant-tabs-content-holder {
    border-left: none !important;
  }
}
