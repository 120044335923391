@import 'src/styles/mixins';
.last-row .services-card {
  width: 100%;
  display: block;
  max-width: 394px;
}
.services-page {
  .container {
    overflow: hidden;
  }
  .services-row {
    display: flex;
    margin-bottom: 27px;
  }
  .services-card-wrapper {
    //display: flex;
    @include breakpoint(large_devices) {
      margin-bottom: 15vh;
    }
    width: 100%;
  }

  .alice-carousel {
    .item {
      padding: 0 5px;
      margin: 0 5px;
    }

    .services-card {
      height: 460px !important;
      .item {
        padding: 10px;
      }
    }
  }
  .services-wrapper {
    .services-card {
      background: white;
      color: var(--black);
      padding: 44px;
      .card-title {
        border-bottom: 1px solid;
        padding-bottom: 15px;
      }
      @include breakpoint(large_devices) {
        margin-right: 34px;
      }
      @include breakpoint(medium_devices) {
        padding: 28px;
        h4 {
          font-size: 24px;
        }
        .paragraph-3 {
          font-size: 18px;
        }
        .card-title {
          margin-bottom: 25px;
          padding-bottom: 25px;
        }
        li {
          margin-top: 7px;
        }
      }
      flex: 1 1;
      &.last-card {
        margin-right: 0;
      }
      .card-content ul {
        padding-left: 20px;
      }
    }
  }

  @include breakpoint(large_devices) {
    //PARALLAX BASICS
    .services-wrapper {
      position: relative;
      min-height: 2400px;
    }
    .services-bottom {
      position: absolute;
      bottom: 0;
    }
    .services-top {
      position: absolute;
      top: 0;
    }
    .services-content {
      position: relative;
      display: block;
      padding-bottom: 100px;
    }
    .services-observed.services-in-view ~ .our-services-content {
      position: fixed;
      width: 100%;
      z-index: 1;
      padding-bottom: 0 !important;
      bottom: 350px;
      //height: 1650px;
    }
    .services-observed.basics-top.basics-in-view ~ .services-content {
      top: 0;
    }
    .services-observed.basics-bottom.basics-in-view ~ .services-content {
      bottom: 400px;
    }
    .services-content.mesh-blue {
      //height: 1650px;
      padding: 0;
      padding-top: 15vh !important;
      @include breakpoint(medium_devices) {
        padding-top: 5vh !important;
      }
    }
  }
}
