@import 'src/styles/mixins';
@include breakpoint(small_devices) {
  .BackgroundVideo.undefined {
    display: none;
  }

  .framer.snap-point {
    background: linear-gradient(181deg, #00097f, transparent);
  }
}

.flexbox.user-experience-text-wrapper {
  height: 100vh;

  @include breakpoint(medium_devices) {
    height: 80vh;
    min-height: 80vh !important;
  }
}

/* FIRST COMPONENET AMINATION */
.fixed-container {
  min-height: calc(100vh);
  position: relative;

  .fixed-element {
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 5%;
    z-index: 1;
    position: absolute;
    min-height: calc(90vh);
    min-height: -webkit-fill-available;

    &.when-fixed {
      position: fixed;
    }

    &.no-padding {
      padding: 0;
    }

    &.not-fixed {
      position: unset;
    }
  }
}
.BackgroundVideo.undefined {
  position: absolute !important;
  top: 85vh !important;
  width: 100vw !important;
  height: 100vh !important;

  @include breakpoint(medium_devices) {
    top: 77vh !important;
  }
}

.user-experience-text-wrapper {
  .user-experience-text {
    width: 517px;
    max-width: 100%;
    margin: 30px 0;
    color: var(--white-700);
  }

  @include breakpoint(wide_devices) {
    .user-experience-text {
      margin: 50px 0;
    }
  }

  @include breakpoint(medium_devices) {
    padding-top: 0;

    h2:nth-of-type(3) {
      margin-bottom: 20px;
    }
    .user-experience-text {
      margin-top: 20px;
      text-align: justify;
    }
  }

  @include breakpoint(small_devices) {
    .user-experience-text {
      width: 100%;
      text-align: justify;
    }
  }
}
