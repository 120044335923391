@import 'src/styles/mixins';

@include breakpoint(large_devices) {
  .our-approach {
    height: 1800px;
    background: white;
    position: relative;
  }

  /// PARALAX
  .approach-in-view ~ .our-approach-section {
    position: fixed;
    width: 100%;
    z-index: 1;
    padding-bottom: 0 !important;
    bottom: 400px;
    //height: 1650px;
  }
  .approach-observed {
    position: absolute;
    bottom: 0;
  }
}
.card-image {
  display: block;
  margin: 0 auto;
}
.title-margin {
  margin-bottom: 28px;
}
.strong {
  font-weight: 600;
}
.our-approach-section {
  text-align: center;
  @include breakpoint(large_devices) {
    .content-wrapper {
      max-width: 100%;
      width: 780px;
    }
  }
  @include breakpoint(medium_devices) {
    .approach-items {
      margin-top: 100px;
    }
    .card-image {
      margin-bottom: 50px;
      width: 140px;
    }
    .approach-wrapper {
      padding-bottom: 100px;
    }

    .our-approach-section {
      padding-top: 100px;
    }
    .block-mobile {
      display: block;
      margin-bottom: 15px;
    }
  }

  @include breakpoint(large_devices) {
    .approach-items {
      display: flex;
      align-items: center;
      margin-top: 123px;
      text-align: left;
    }

    .approach-left-item {
      flex: 1;
    }

    .approach-right-item {
      flex: 1;
    }

    .approach-items.inversed {
      flex-direction: row-reverse;
      text-align: right;
    }

    .our-approach {
      position: relative;
    }
  }
}
