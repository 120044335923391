.loader {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &.transparent {
    border: 2px solid #FFF;
    border-bottom-color: transparent;
  }

  &.dark {
    border: 2px solid #FFF;
    border-bottom-color: transparent;
  }

  &.light {
    border: 2px solid #1E212A;
    border-bottom-color: transparent;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
