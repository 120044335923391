@import 'src/styles/mixins';
.services-hero {
  color: var(--black);
  background-color: var(--white);
  .services-header {
    @include breakpoint(medium_devices) {
      display: none;
    }
  }
  .hero-content-wrapper {
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 65px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    @include breakpoint(medium_devices) {
      padding-top: 0;
    }
    @include breakpoint(large_devices) {
      min-height: 100vh;
    }
    @include breakpoint(full_hd) {
      min-height: 100vh;
    }
    .services-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include breakpoint(medium_devices) {
        padding: 0 5%;
      }
      .services-title {
        z-index: 1;
        span {
          color: var(--royal-800);
        }
      }
    }
    .hero-subheading {
      max-width: 550px;
      text-align: justify;
      margin-top: 53px;
    }
    .herobg {
      max-width: 100%;
      position: absolute;
      z-index: 0;
      right: -100px;
      @include breakpoint(large_devices) {
        bottom: 0;
        right: 0;
      }
    }
  }
}
