@import 'src/styles/mixins';

.toolbox-wrapper {
  display: flex;
  width: 100%;
  @include breakpoint(medium_devices) {
    flex-flow: column;
  }
}
.toolbox-content {
  flex: 1 1;
  padding: 10px;
}
.toolbox-items span {
  display: inline-block;
  border: 1px solid #ffffff61;
  margin: 3px 6px 6px 0;
  padding: 3px 8px;
  border-radius: 7px;
}
.our-way {
  background: rgb(5, 15, 122);
  background: -moz-linear-gradient(142deg, rgba(5, 15, 122, 1) 0%, rgba(8, 10, 35, 1) 90%);
  background: -webkit-linear-gradient(142deg, rgba(5, 15, 122, 1) 0%, rgba(8, 10, 35, 1) 90%);
  background: linear-gradient(142deg, rgba(5, 15, 122, 1) 0%, rgba(8, 10, 35, 1) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#050f7a",endColorstr="#080A23",GradientType=1);
  .our-way-ico-wrapper {
    margin: 10vh 0;
    &.services-our-way {
      margin: 0;
    }
    .our-way-content {
      @include breakpoint(large_devices) {
        margin-bottom: 50px;
        display: flex;
      }
      .our-way-content-element {
        display: flex;
        align-items: center;
        flex: 1 1 0px;
        margin-bottom: 45px;
        @include breakpoint(large_devices) {
          flex-flow: column;
          padding: 45px;
          text-align: center;
          margin-bottom: 0;
        }
        .our-way-ico {
          margin-bottom: 15px;
          @include breakpoint(medium_devices) {
            margin-right: 20px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.what-special {
  z-index: 2;
}
.join-the-team-wrapper {
  padding-top: 90px;
  @include breakpoint(large_devices) {
    margin-top: 22vh;
  }
  .join-the-team {
    @include breakpoint(large_devices) {
      display: flex;
    }
    width: 100%;
    &.team-mesh {
      background-color: hsla(230, 70%, 48%, 1);
      background-image: radial-gradient(at 99% 41%, hsla(229, 70%, 48%, 1) 0px, transparent 50%),
        radial-gradient(at 93% 88%, hsla(236, 98%, 22%, 1) 0px, transparent 50%),
        radial-gradient(at 49% 50%, hsla(232, 84%, 44%, 1) 0px, transparent 50%),
        radial-gradient(at 9% 89%, hsla(236, 98%, 22%, 1) 0px, transparent 50%),
        radial-gradient(at 90% 6%, hsla(230, 70%, 48%, 1) 0px, transparent 50%),
        radial-gradient(at 53% 10%, hsla(226, 98%, 23%, 1) 0px, transparent 50%),
        radial-gradient(at 9% 11%, hsla(235, 85%, 35%, 1) 0px, transparent 50%);
      padding-bottom: 7vh;
      @include breakpoint(large_devices) {
        max-height: 580px;
      }
    }
    img.team-image {
      max-width: 100%;
      width: 510px;
      @include breakpoint(large_devices) {
        margin-top: -155px;
        display: block;
        height: -webkit-fill-available;
        padding-left: 40px;
      }
    }
    .join-the-team-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 5%;
      @include breakpoint(large_devices) {
        margin-top: -265px;
      }
      .paragraph-3 {
        margin-bottom: 20px;
        &.loveit-liveit {
          display: none;
          @include breakpoint(large_devices) {
            display: block;
            align-self: end;
            margin-bottom: -97px;
            display: block;
            position: relative;
            margin-top: 42px;
            width: 100%;
            .with-heart {
              position: absolute;
              right: 0px;
              bottom: -45px;
            }
          }
        }
      }
    }
  }
}
.expertise {
  padding-top: 110px;
  padding-bottom: 200px;
  h2 {
    margin-bottom: 55px;
  }
  .toolbox.subheading {
    margin-bottom: 30px;
  }

  @include breakpoint(large_devices) {
    .m-bot-45 {
      margin-bottom: 65px !important;
    }
  }
  .what-makes-us-special-content-cards-desktop {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 90px;

    .what-makes-us-special-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: auto;
      background: var(--neutrals-50);
      padding: 50px 40px;

      .img-and-title-wrapper {
        margin-bottom: 32px;
        display: flex;
        align-items: center;

        img {
          width: 62px;
          height: 62px;
          margin-right: 23px;
        }

        h5 {
          color: var(--neutrals-800);
          width: 100%;
        }
      }

      .description {
        color: var(--neutrals-700);
      }
    }
  }

  .expandable-cards-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 22px;

    .card-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background: transparent;
      padding: 24px;

      .card-item-content {
        display: flex;
        width: 100%;
        height: 100%;

        img {
          height: 45px;
          width: auto;
          margin-right: 23px;
        }

        div {
          width: 100%;
          text-align: left;
          color: var(--white);
          font-size: 22px !important;
          font-weight: 500 !important;
          line-height: 130%;
          font-family: paralucent, sans-serif !important;
        }

        .chevron-icon {
          width: 24px;
          height: 12px;
          margin: auto 0 auto 0;
          transition: transform 0.5s;

          &.expanded {
            transform: rotateX(180deg);
            transition: transform 0.5s;
          }
        }
      }

      .card-item-content-expanded {
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.25s ease-out;

        div {
          margin: 28px 0 0 0;
          width: 100%;
          text-align: left;
          color: var(--white);
        }

        &.show {
          max-height: 500px;
          transition: max-height 0.25s ease-in;
        }
      }
    }
  }

  .form-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include breakpoint(large_devices) {
      margin-top: 55px;
    }
    @include breakpoint(medium_devices) {
      margin-bottom: 110px;
    }

    .title-column {
      display: flex;
      flex-direction: column;
      width: 48%;

      .paragraph-5 {
        display: none;
      }

      .mobile-screens-form-section-subtitle {
        display: none;
        color: var(--neutrals-50);
        font-size: 13.98px;
        line-height: 150%;
        font-family: paralucent-text, sans-serif;
        font-weight: 300;
      }
    }

    .form-column {
      display: flex;
      flex-direction: column;
      width: 48%;
    }
  }
  .white-text {
    letter-spacing: 1px;
  }
  @include breakpoint(medium_devices) {
    padding-top: 40px;
    padding-bottom: 250px;
    flex-direction: column !important;
    .wrap-img {
      width: 87px;
    }

    h2 {
      margin: 55px 0;
    }
    .what-makes-us-special-content {
      h2.capitalised.mobile {
        font-size: 35px;
      }
    }
    .white-text {
      margin-bottom: 40px;
      .paragraph-3 {
        margin-top: 20px;
      }
    }

    .what-we-create-content-text {
      width: 100%;
    }

    .form-section {
      flex-direction: column;
      margin-top: 60px;

      .title-column {
        width: 100%;
        margin-bottom: 60px;

        h3 {
          margin-bottom: 12px;
        }

        .paragraph-5 {
          display: block;
          text-align: justify;
          margin-bottom: 33px;
        }

        .mobile-screens-form-section-subtitle {
          display: block;
          margin-bottom: 34px;
          text-align: justify;
        }
      }

      .form-column {
        width: 100%;

        .checkbox-wrapper {
          margin: 25px 0 50px 0;

          .checkbox-text {
            font-size: 14px;
            line-height: 115.5%;
            color: var(--white);
          }
        }
      }
    }
  }
}

.services-hero .hero-content-wrapper .herobg {
  @include breakpoint(large_devices) {
    bottom: -100px;
    right: -150px;
  }
  @include breakpoint(wide_devices) {
    width: 800px;
    right: -800px;
    bottom: -275px;
  }
}
