@import 'src/styles/mixins.scss';

.header-button:hover {
  background: #0047bb !important;
  color: white !important;
}

.header-button:hover .button-arrow-container path {
  fill: white !important;
}

.header-button:hover .button-arrow-container {
  border-left: 1px solid #ffffff !important;
}

.header-button {
  border: 1px solid #0047bb !important;
  color: #0047bb !important;
  background: white !important;

  .button-arrow-container {
    border-left: 1px solid #0047bb !important;

    path {
      fill: #0047bb !important;
    }
  }
}
.section-header-content {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  > .separator {
    width: 100%;
    height: 1px;
    background-color: var(--neutrals-800);
    margin-bottom: 32px;
  }

  .box {
    display: flex;
    overflow: hidden;
    position: relative;
    align-self: stretch;
    box-sizing: border-box;
    align-items: center;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    margin-bottom: 0;
    justify-content: space-between;

    .logo {
      width: 135px;
      height: 40px;
      position: relative;

      @include breakpoint(medium_devices) {
        height: 35px;
        width: auto;
      }
    }

    .right-part {
      display: flex;
      align-content: center;
      justify-content: flex-end;

      @include breakpoint(medium_devices) {
        margin-left: 40px;
        width: 100%;
      }

      .right-part-text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.with-margin-right {
          margin-right: 20px;
        }

        @include breakpoint(medium_devices) {
          display: none;
        }
      }

      .footer-menu {
        > span {
          margin-left: 24px;
        }
      }

      a {
        display: flex;
      }

      .header-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 13px;
        padding-top: 3px;
        float: left;

        .header-icons-item {
          height: 40px;
          width: 40px;
          border-radius: 4px;
          object-fit: cover;

          &:first-of-type {
            margin-right: 8px;
          }
        }

        @include breakpoint(medium_devices) {
          display: none;
        }
      }
    }

    .header-icons-wrapper {
      position: relative;

      .right-part-text {
        padding-top: 10px;

        > div {
          color: var(--neutrals-700);
        }
      }
    }
  }

  @include breakpoint(medium_devices) {
    width: calc(100% - 52px);

    > .separator {
      margin-bottom: 12px;
    }
  }
}
