@import 'src/styles/mixins';

.hero-content {
  background: var(--white);
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .hero-subheading {
    margin-top: 20px;
  }

  h1 {
    color: #012584;
  }

  .middle-row {
    display: flex;

    .small-text-wrapper {
      display: flex;
      color: var(--blue);
      margin-right: 20px;
    }
    @include breakpoint(tablets) {
      align-items: center;
      justify-content: center;
      p {
        text-align: center;
      }
    }
  }

  @include breakpoint(extra_large_devices) {
    .hero-content-text {
      max-width: 1280px;
      margin: 0 auto;
    }
  }
  @include breakpoint(wide_devices) {
    .hero-content-text {
      max-width: 1250px;
      margin: 0 auto;
    }
  }
  @include breakpoint(medium_devices) {
    padding: 0 5% 0 5%;
    height: 76vh;

    .middle-row {
      margin-top: 28px;
      width: 100%;
    }

    .small-text-wrapper {
      margin: 0;
    }
  }
}
@include breakpoint(tablets) {
  .hero-content {
    padding: 0 10%;
    h1 {
      font-size: 80px;
      text-align: center;
    }
  }
}
