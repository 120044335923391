@import 'src/styles/mixins';

.what-makes-us-special-content-cards-mobile {
  .what-makes-us-special-card {
    padding: 34px;
    margin-bottom: 25px;
    .img-and-title-wrapper {
      display: flex;
      align-items: center;
      padding: 15px 0;
      img {
        margin-right: 20px;
        width: 65px;
      }
    }
  }
}

.our-values-content {
  .what-you-can-expect-from-us-content-text {
    @include breakpoint(large_devices) {
      margin-top: 150px;
    }
    margin-bottom: 72px;
  }
  .what-you-can-expect-from-us-cards-desktop {
    grid-template-columns: repeat(3, 1fr);
    .card-item {
      background: rgba(17, 17, 17, 0.07);
      width: 385px;
      height: 230px;
      .flip-card-front {
        span {
          color: #1e212a;
        }
      }
    }
  }
  .what-you-can-expect-from-us-cards-mobile {
    .card-item {
      background: rgba(17, 17, 17, 0.07);
      width: 300px;
      @include breakpoint(medium_devices) {
        width: 100%;
      }
      .card-item-content {
        span {
          color: #1e212a;
        }
      }
    }
  }
}
@include breakpoint(tablets) {
  .content-wrapper.larger-height {
    padding: 0 5%;
  }
  .our-values-content .what-you-can-expect-from-us-cards-mobile .card-item {
    width: 100%;
  }
}
