@import 'src/styles/mixins';

li.alice-carousel__stage-item {
  padding-right: 1px;
}

.what-makes-us-special-content.our-values {
  position: relative;
  padding-bottom: 100px;
  .what-makes-us-special-card {
    background: var(--neutrals-100);
  }
}
.projects-and-team-content {
  background: var(--white);
  color: var(--black);
  z-index: 2;
  position: relative;
  padding: 0;

  .flexbox {
    max-width: 100vw !important;
  }

  .section-header-content {
    max-width: 1250px;
    margin: 0 auto;
    padding: 50px 0 150px 0;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 0;

    &.slider-wrapper {
      max-width: 100vw !important;
      padding-top: 0;
    }

    .content-wrapper-title {
      width: 100%;

      h1 {
        width: 100%;
        font-weight: 900;
      }
    }

    .content-wrapper-text {
      width: 76%;
      margin-top: 40px;
      color: var(--neutrals-700);
    }

    .our-clients-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 171px 0 25vh 0;
      width: 100%;

      .slick-slider {
        width: 100%;

        .slick-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100px;
        }

        img {
          max-height: 83px;
          max-width: 184px;
          height: auto;
          width: auto;
        }
      }
    }

    .our-latest-projects {
      display: flex;
      flex-direction: column;
      margin-bottom: 150px;

      h2 {
        margin-bottom: 118px;
      }

      .client-logo-wrapper-mobile {
        display: none;
      }

      .slick-slider {
        width: 90%;
        //height: 550px;
        margin: 0 auto;

        .slick-list {
          height: 100%;
        }

        .slick-track {
          height: 100%;
        }

        .slick-arrow.slick-prev {
          position: absolute;
          left: -50px;
          width: auto;
          padding: 0;
          display: block;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;

          img {
            height: 25px;
            width: fit-content;
          }
        }

        .slick-arrow.slick-next {
          position: absolute;
          right: -50px;
          width: auto;
          padding: 0;
          display: block;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;

          img {
            height: 25px;
            width: fit-content;
          }
        }

        .slick-dots {
          margin: 64px 0 0 0;
          display: flex !important;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: unset !important;
          left: 50%;
          transform: translateX(-50%);

          li {
            position: relative;
            display: flex;
            width: auto;
            height: auto;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
              width: 6.6px;
              height: 6.6px;
              background: rgba(0, 0, 0, 0.33);
              padding: unset !important;

              &::before {
                display: none;
              }
            }

            &.slick-active {
              button {
                width: 11px !important;
                height: 11px !important;
                background: #171c8f !important;
              }
            }
          }
        }

        .slick-slide {
          display: flex;
          align-items: stretch;
          justify-content: center;

          > div:first-of-type {
            width: 100% !important;
          }

          .project-card {
            display: flex !important;
            justify-content: space-between !important;
            width: 100% !important;
            height: 100% !important;
            align-items: stretch !important;

            .project-info-column {
              width: 48%;
              height: 100%;
              display: flex;
              flex-direction: column;
              color: var(--neutrals-900);
              padding: 56px 36px;
              position: relative;

              .client-logo-wrapper {
                height: 83px;
                width: 184px;
                display: flex;
                justify-content: center;
                margin-bottom: 45px;
                position: absolute;
                top: 56px;
                left: 36px;

                img {
                  max-height: 83px;
                  max-width: 184px;
                  width: auto;
                  height: auto;
                }
              }

              .selected-project-statement {
                margin-bottom: 24px;
                width: 90%;
                margin-top: calc(56px + 83px);
              }

              .selected-project-key-practices {
                margin: 0;
                padding-left: 25px;
                list-style: none !important;

                li::before {
                  content: '\2022';
                  color: var(--neutrals-700);
                  font-weight: bold;
                  font-size: 8px;
                  display: inline-block;
                  margin-left: -20px;
                  margin-right: 10px;
                  vertical-align: top;
                }
              }
            }

            .testimonial-column {
              width: 48%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 56px 36px;
              background: #171c8f;
              border-top-right-radius: 14px;
              border-bottom-right-radius: 14px;
              position: relative;

              .testimonial-text {
                margin-bottom: 50px;
                color: var(--white);
                width: 90%;
              }

              h5 {
                margin-bottom: 2px;
                color: var(--white) !important;
                width: 90%;
              }

              .testimonial-person-position {
                color: rgba(255, 255, 255, 0.4);
                width: 80%;
              }

              .quotes-icon {
                position: absolute;
                bottom: 47px;
                right: 52px;
                width: auto;
                height: 34px !important;
              }
            }
          }
        }
      }
    }

    .about.our-team-wrapper {
      min-height: 100%;
      .separator-150 {
        position: absolute;
        bottom: 0;
      }
      /* PARALAX POSITIONING */

      @include breakpoint(large_devices) {
        .separator-150.in-view ~ .our-team {
          position: fixed;
          width: 100%;
          max-width: 1250px;
          bottom: 0;
        }
      }

      .our-team {
        padding: 0 0 150px 0;

        @include breakpoint(large_devices) {
          /*IMPORTANT FOR PARALAX */
          position: relative;
          padding: 0 0 5vh 0;
        }
        max-width: 1250px;
        width: 100%;
        bottom: 0;

        > h2 {
          margin-bottom: 40px;
        }

        .our-team-note {
          display: flex;
          margin: 10vh 0;
        }

        .our-team-note-statistics-desktop {
          display: flex;

          .statistics-item {
            background-color: var(--white);
            display: flex;
            flex-direction: column;
            width: 228px;

            h2 {
              color: var(--black);
              width: 100%;
            }

            > div {
              width: 100%;
            }

            &:not(:last-of-type) {
              margin-right: 144px;
            }
          }

          @include breakpoint(extra_large_devices) {
            margin-bottom: 100px;
          }
        }

        .our-team-note-statistics-mobile {
          display: none;
        }

        .our-team-text {
          width: 90%;
          margin-bottom: 85px;
        }

        .our-team-cards {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 50.5px;

          .our-team-cards-item {
            display: flex;
            flex-direction: column;
            width: 205px;

            .img-container {
              width: 205px;
              height: 200px;
              margin-bottom: 18px;
              border-radius: 27.24px;
              overflow: hidden;
              position: relative;

              .gray-bg-div {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                background: url('~/src/assets/images/team/gray-portrait-bg.png') center center no-repeat;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
                z-index: 2;
              }
            }

            .team-member-name {
              font-family: paralucent, sans-serif !important;
              font-weight: 500;
              font-size: 20px;
              line-height: 150%;
              color: var(--neutrals-800);
              margin-bottom: 8px;
            }

            div.paragraph-6 {
              width: 95%;
              text-align: left;
            }
          }
        }
      }
    }
  }

  @include breakpoint(medium_devices) {
    padding: 45px 5% 0 5%;

    .content-wrapper {
      padding-top: 55px;

      &.no-padding-top {
        padding-top: 0 !important;
      }

      .content-wrapper-title {
        > h2 {
          text-align: left;
        }
      }

      .content-wrapper-text {
        width: 95%;
        margin-top: 24px;
        text-align: justify;
      }

      .our-clients-icons {
        margin: 100px 0 110px 0;
        .slick-dots {
          bottom: -40px;
          display: none !important;
        }

        .slick-active {
          button:before {
            opacity: 0.75;
            color: #171c8f;
            font-size: 11px;
            line-height: 2.1;
          }
        }
        button.slick-arrow.slick-next {
          display: none !important;
        }
      }

      .our-latest-projects {
        width: 100% !important;
        margin: 0 0 100px 0 !important;

        .client-logo-wrapper-mobile {
          display: flex;
          width: 100%;
          justify-content: center;
          margin-bottom: 25px;
          min-height: 84px;
        }

        .slick-slider {
          width: 100% !important;
          //height: 425px !important;

          .slick-arrow.slick-prev,
          .slick-next {
            display: none !important;
          }

          .slick-dots {
            margin: 32px 0 0 0 !important;
          }
        }

        > h2 {
          margin-bottom: 26px;
          text-align: center;
        }

        .project-card {
          .project-info-column {
            display: none !important;
          }

          .testimonial-column {
            width: 100% !important;
            border-radius: 14px !important;
            padding: 42px 22px !important;

            .quotes-icon {
              height: 34px !important;
              bottom: 49px !important;
              right: 34px !important;
            }
          }
        }
      }

      .our-team {
        padding: 0 0 70px 0 !important;

        .our-team-note {
          margin: 65px 0 46px 0 !important;
        }

        .our-team-note-statistics-desktop {
          display: none;
        }

        .our-team-note-statistics-mobile {
          display: flex !important;
          flex-direction: column;

          .statistics-item {
            background-color: var(--white);
            display: flex;
            width: 100%;
            margin-bottom: 34px;
            align-items: center;
            padding-left: 30px;

            h2 {
              color: var(--black);
              margin-right: 34px;
              font-size: 76px !important;
              @include breakpoint(medium_devices) {
                font-size: 36px !important;
                margin-right: 20px;
                width: 50px;
                text-align: right;
              }
            }

            > div {
              width: auto;
            }
          }
        }

        .our-team-text {
          width: 75%;
          margin-bottom: 55px;
        }

        .our-team-cards {
          display: none;
        }

        > .alice-carousel {
          margin: 0;

          .alice-carousel__stage-item {
            .img-container {
              line-height: 0 !important;
            }
          }

          li {
            &:not(:last-of-type) {
              .our-team-mobile-carousel-card {
                margin-right: 50px;
              }
            }
          }

          .our-team-mobile-carousel-card {
            display: flex;
            flex-direction: column;

            .img-container {
              width: 205px;
              height: auto;
              margin-bottom: 18px;
              border-radius: 27.24px;
              overflow: hidden;
              position: relative;

              .gray-bg-div {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                background: url('~/src/assets/images/team/gray-portrait-bg.png') center center no-repeat;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: relative;
                z-index: 2;
              }
            }

            .team-member-name {
              font-family: paralucent, sans-serif !important;
              font-weight: 500;
              font-size: 20px;
              line-height: 150%;
              color: var(--neutrals-800);
              margin-bottom: 8px;
            }

            div.paragraph-6 {
              width: 95%;
              text-align: left;
            }
          }

          .alice-carousel__dots {
            margin-top: 69px;

            .alice-carousel__dots-item {
              background: rgba(0, 0, 0, 0.33);
              height: 6.6px;
              width: 6.6px;
              vertical-align: middle;

              &.__active {
                background: #171c8f;
                height: 11px;
                width: 11px;
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(ultrawide_devices) {
    .our-latest-projects {
      margin-bottom: 25vh !important;
    }
  }
}
@include breakpoint(large_devices) {
  .about-page {
    .projects-and-team-content {
      height: 2900px;
    }
  }
}
