@import "src/styles/mixins";

.what-special {
    z-index: 2;
}

.what-makes-us-special-content {
    padding-top: 110px;
    padding-bottom: 200px;

    h2 {
        margin-bottom: 90px;
    }

    .what-makes-us-special-content-cards-desktop {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin-bottom: 90px;

        .what-makes-us-special-card {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            height: auto;
            background: var(--neutrals-50);
            padding: 50px 40px;

            .img-and-title-wrapper {
                margin-bottom: 32px;
                display: flex;
                align-items: center;

                img {
                    width: 62px;
                    height: 62px;
                    margin-right: 23px;
                }

                h5 {
                    color: var(--neutrals-800);
                    width: 100%;
                }
            }

            .description {
                color: var(--neutrals-700);
            }
        }
    }

    .expandable-cards-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 22px;

        .card-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            background: var(--white);
            padding: 24px;

            .card-item-content {
                display: flex;
                width: 100%;
                height: 100%;

                img {
                    height: 45px;
                    width: auto;
                    margin-right: 23px;
                }

                div {
                    width: 100%;
                    text-align: left;
                    color: var(--neutrals-800);
                    font-size: 22px !important;
                    font-weight: 500 !important;
                    line-height: 130%;
                    font-family: paralucent, sans-serif !important;
                }

                .chevron-icon {
                    width: 24px;
                    height: 12px;
                    margin: auto 0 auto 0;
                    transition: transform 0.5s;

                    &.expanded {
                        transform: rotateX( 180deg ) ;
                        transition: transform 0.5s;
                    }
                }
            }

            .card-item-content-expanded {
                display: flex;
                align-items: center;
                width: 100%;
                overflow: hidden;
                max-height: 0;
                transition: max-height 0.25s ease-out;

                div {
                    margin: 28px 0 0 0;
                    width: 100%;
                    text-align: left;
                    color: var(--neutrals-800);
                }

                &.show {
                    max-height: 500px;
                    transition: max-height 0.25s ease-in;
                }
            }
        }
    }

    .form-section {
        display: flex;
        justify-content: space-between;
        margin-top: 115px;

        .title-column {
            display: flex;
            flex-direction: column;
            width: 48%;

            .paragraph-5 {
                display: none;
            }

            .mobile-screens-form-section-subtitle {
                display: none;
                color: var(--neutrals-50);
                font-size: 13.98px;
                line-height: 150%;
                font-family: paralucent-text, sans-serif;
                font-weight: 300;
            }
        }

        .form-column {
            display: flex;
            flex-direction: column;
            width: 48%;
        }
    }

    @include breakpoint(medium_devices) {
        padding-top: 40px;
        padding-bottom: 300px;
        flex-direction: column !important;

        h2 {
            margin: 55px 0;
        }

        .what-we-create-content-text {
            width: 100%;
        }

        .form-section {
            flex-direction: column;
            margin-top: 100px;

            .title-column {
                width: 100%;

                h3 {
                    margin-bottom: 12px;
                }

                .paragraph-5 {
                    display: block;
                    text-align: justify;
                    margin-bottom: 33px;
                }

                .mobile-screens-form-section-subtitle {
                    display: block;
                    margin-bottom: 34px;
                    text-align: justify;
                }
            }

            .form-column {
                width: 100%;

                .checkbox-wrapper {
                    margin: 25px 0 50px 0;

                    .checkbox-text {
                        font-size: 14px;
                        line-height: 115.5%;
                        color: var(--white);
                    }
                }
            }
        }
    }
}
