@import "src/styles/mixins";

.flexbox {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh);
}

.under-construction-content {
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;

  .under-construction-content-divider {
    width: 76px;
    height: 1px;
    margin: 35px 0;
    background: var(--white);
    position: relative;
    align-self: flex-start;
    box-sizing: border-box;
    border-color: transparent;
  }

  .under-construction-content-text {
    height: auto;
    align-self: stretch;
    text-align: left;
    text-decoration: none;
  }

  .contact-info {
    @include breakpoint(medium_devices) {
      margin-top:5%;
    }

    .breaktext {
      clear: both;
      display: block;
    }
  }

  @include breakpoint(medium_devices) {
    align-items: flex-start;
  }
}
