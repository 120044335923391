@import 'src/styles/mixins.scss';

button {
  border-radius: 45px;
  padding: 25px 20px 22px;
  display: flex;
  align-items: center;
  height: 47px;
  cursor: pointer;
  font-size: 20px;
  line-height: 100%;
  font-family: paralucent, sans-serif;
  font-weight: 500;
  position: relative;
  transition: color 0.5s, transform 0.2s, background-color 0.2s;

  .button-arrow-container {
    margin-left: auto;
    padding-left: 14px;

    .button-arrow-icon {
      width: 14px;
      height: 14px;
    }
  }

  &.dark {
    background: var(--blue) !important;
    &:hover {
      background: var(--neutrals-50) !important;
      color: var(--neutrals-800);

      .loader {
        border: 2px solid #1e212a;
        border-bottom-color: transparent;
      }

      .button-arrow-container {
        border-left: 1px solid var(--neutrals-800);

        path {
          fill: var(--neutrals-800);
        }
      }
    }
  }

  &.transparent {
    border: 1px solid var(--neutrals-50);
    color: var(--neutrals-50);
    background: transparent;

    .button-arrow-container {
      border-left: 1px solid var(--neutrals-50);
    }

    &:hover {
      background: var(--neutrals-50);
      color: var(--neutrals-800);

      .loader {
        border: 2px solid #1e212a;
        border-bottom-color: transparent;
      }

      .button-arrow-container {
        border-left: 1px solid var(--neutrals-800);

        path {
          fill: var(--neutrals-800);
        }
      }
    }
  }

  &.light {
    border: 2px solid var(--neutrals-500);
    color: var(--neutrals-800);
    background: var(--neutrals-50);

    .loader {
      border: 2px solid #1e212a;
      border-bottom-color: transparent;
    }

    .button-arrow-container {
      border-left: 1px solid var(--neutrals-800) !important;
    }
  }

  &.dark {
    border: 1px solid var(--neutrals-50);
    color: #eeeeef;
    background: linear-gradient(106.53deg, #050f7a 6.58%, #020845 97.61%);

    .loader {
      border: 2px solid #fff;
      border-bottom-color: transparent;
    }

    .button-arrow-container {
      border-left: 1px solid var(--neutrals-50);
    }
  }

  @include breakpoint(medium_devices) {
    margin: 0 auto !important;
    width: 100%;
    //min-width: 165px !important;

    .button-arrow-container {
      padding-left: 6px;
    }
  }
}
