@import 'src/styles/mixins';

.ReactModalPortal {
  z-index: 999;
  position: relative;
}
.ReactModal__Overlay {
  background-color: #000000ab !important;
}
.ReactModal__Content {
  padding: 6em 6em 0 6em !important;
  max-width: 1250px;
  margin: 0 auto;
  overflow-x: hidden;
  .semibold {
    font-weight: 500;
  }
  .close-modal {
    background: transparent;
    border: none;
    position: absolute;
    right: 25px;
    top: 35px;
    img {
      rotate: 45deg;
    }
  }
  .modal-logo {
    min-width: 200px;
    margin-bottom: 4em;
  }
  .modal-title {
    text-transform: uppercase;
  }
  .modal-tags {
    margin: 3em auto;
    display: inline-block;
    .modal-tag {
      background: var(--light-primary);
      color: white;
      padding: 10px;
      margin: 0 5px 0 0;
      font-family: 'paralucent';
      border-radius: 9px;
      float: left;
      margin-bottom: 0.5em;
      font-weight: 300;
    }
  }
  .modal-info {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 2em;
    clear: both;
    .modal-info-content {
      margin-right: 4em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'paralucent-text';
      .info-icon {
        width: 25px;
        margin-right: 10px;
      }
      a {
        font-family: paralucent-text, sans-serif;
        color: var(--blue);
      }
    }
  }
  .modal-boxes {
    display: flex;
    width: 100%;
    margin-right: 1em;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 2em;

    .modal-box {
      width: 100%;
      background: var(--neutrals-100);
      padding: 3em;
      font-family: 'paralucent-text';
      color: var(--blue);
      font-size: 18px;
      .score-block {
        width: 20px;
        height: 20px;
        display: block;
        float: left;
        margin-right: 10px;
        border: 1px solid var(--light-primary);
      }
    }
    .score-value.bad .score-block {
      background-color: var(--light-primary);
    }
    .score-value.enough .score-block:nth-child(-n + 2) {
      background-color: var(--light-primary);
    }
    .score-value.good .score-block:nth-child(-n + 3) {
      background-color: var(--light-primary);
    }
    .score-value.very-good .score-block:nth-child(-n + 4) {
      background-color: var(--light-primary);
    }
    .score-value.excellent .score-block {
      background-color: var(--light-primary);
    }
  }
  .modal-body {
    background: var(--neutrals-100);
    font-family: 'paralucent-text';
    padding: 3em 3em;
    position: relative;

    .modal-text {
      font-size: 24px;
      line-height: 1.6em;
      margin-bottom: 2em;
    }
    .modal-person {
      display: flex;
      .modal-person-image {
        height: 61px;
        border-radius: 50%;
        float: left;
      }
      .modal-person-info {
        display: flex;
        margin-right: 1em;
        justify-content: space-between;
        flex-direction: row;
        flex: 1;
        .modal-person-details {
          display: flex;
          flex-flow: column;
          justify-content: center;
          padding-left: 1em;
          .modal-person-name {
            font-size: 24px;
            font-family: 'paralucent';
            color: var(--blue);
            font-weight: 500;
          }
          .modal-company-position {
            text-transform: uppercase;
            color: var(--neutrals-500);
            font-size: 16px;
          }
        }
      }
    }
  }
  .modal-summary {
    padding: 2em 0;
    font-family: 'paralucent';
    font-size: 24px;
    line-height: 1.6em;
    font-weight: 300;
  }
  .services-rendered {
    padding: 2em;
    font-family: 'paralucent';
    font-size: 24px;
    line-height: 1.6em;
    font-weight: 300;
  }
}
@include breakpoint(large_devices) {
  img.quotes-blue {
    position: absolute;
    bottom: 4em;
    right: 4em;
  }
}
@include breakpoint(medium_devices) {
  .ReactModal__Content {
    padding: 1em !important;
    padding-top: 4em !important;
    max-width: 100%;
  }
  .modal-info-content {
    margin-bottom: 15px;
  }
  .ReactModal__Content .modal-body {
    padding: 2em 1em;
  }
  img.quotes-blue {
    margin-bottom: 1.5em;
  }
  .ReactModal__Content .modal-summary {
    padding: 2em 0.3em;
  }
  img.quotes-blue {
    position: relative;
  }
  .ReactModal__Content .close-modal {
    width: 34px !important;
    right: 2em;
    top: 1em;
  }
  .ReactModal__Content .modal-body .modal-text {
    font-size: 18px;
  }
  .ReactModal__Content .modal-summary {
    font-size: 18px;
  }
}

/* Slider */
.slider-container {
  .slide-text {
    font-family: 'paralucent-text';
    font-size: 24px;
    margin-top: 1em;
    line-height: 1.5em;
  }

  .slick-slider {
    width: 100% !important;
    height: auto !important;
  }

  .person-image {
    width: 61px;
    height: 61px;
    border-radius: 50%;
    background: #00000052;
    float: left;
  }

  .slide-person {
    display: flex;
    position: relative;
    margin: 2em 0;
  }
  .slide-body button {
    clear: both;
  }
  .slick-slide > div {
    padding: 10px;
    display: flex;
  }
  .slide-body {
    background-color: var(--light-primary);
    flex: 1;
    padding: 3em;
    color: var(--white);
  }
  .slide-heading {
    background: var(--neutrals-100);
    padding: 3em;
    padding-top: 3.5em;
  }
  .slide {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 2em;
    overflow: hidden;
  }
  .slide-heading img {
    height: 45px;
  }

  .slick-slider {
    height: auto !important;
  }
  .slick-arrow {
    height: 25px;
  }
  button::after {
    content: '→';
    padding-left: 0.5em;
    margin-left: 1.5em;
    border-left: 1px solid;
  }
  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-slide {
    height: auto;
    flex: 1;
  }
  .person-info {
    display: flex;
    position: relative;
    flex-flow: column;
    padding-left: 1em;
    vertical-align: middle;
    height: 100%;
    p {
      font-family: 'paralucent-text';
      font-size: 24px;
      &.company-position {
        font-family: 'paralucent';
        text-transform: uppercase;
        color: var(--white);
        font-size: 16px;
        opacity: 40%;
        padding-top: 0.3em;
        letter-spacing: 1px;
      }
    }
  }
  @include breakpoint(medium_devices) {
    margin-bottom: 3em;
    img.quotes-icon {
      //position: absolute;
      bottom: 7em;
      right: 2em;
      padding-top: 1em;
    }
    .slide-body {
      max-width: 100%;
    }
    .slide {
      position: relative;
    }
    .slide-body {
      padding: 1.5em;
      border-radius: 2em;
      padding-bottom: 2.5em;
    }
    img.person-image {
      display: none;
    }
    button.transparent {
      width: auto;
      float: left;
    }
    .slide-heading {
      background: var(--white);
      img {
        margin: 0 auto;
      }
    }
    .projects-and-team-content .content-wrapper .our-latest-projects .slick-slider {
      height: auto !important;
    }

    .person-info {
      padding-left: 0 !important;
    }
    .slider-container .slide-text {
      font-size: 18px;
    }
  }
}
.modal-open {
  overflow: hidden !important;
}
