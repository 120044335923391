@import 'src/styles/mixins.scss';

.header-container {
  width: 100%;
  background: transparent;
  position: absolute;
  height: 70px;
  z-index: 9999;

  &.fixed {
    position: fixed;
    z-index: 10;
  }

  @include breakpoint(small_devices) {
    height: 100px;
  }

  @include breakpoint(extra_small_devices) {
    height: 74px;
  }
}
