@import 'src/styles/mixins.scss';

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  @include breakpoint(medium_devices) {
    margin-bottom: 50px;
  }

  .form-thank-you {
    display: flex;
    flex-direction: column;
    color: var(--white);
    width: 100%;

    .paragraph-3 {
      margin-bottom: 26px;
      display: flex;
      align-items: center;

      img {
        width: 31px;
        height: 31px;
        margin-right: 12px;
      }
    }
  }

  > * {
    box-sizing: border-box;
  }

  .label {
    font-family: Paralucent, sans-serif;
    font-weight: 500;
    font-size: 16.89px;
    line-height: 100%;
    color: var(--neutrals-0);
    margin-bottom: 10px;

    > span {
      margin-left: 4px;
      color: var(--white);
    }
  }

  .input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    outline: 1px solid var(--royal-800);
    border: 5px solid rgba(0, 0, 0, 0);
    border-radius: 6.81px;
    background: var(--neutrals-0);
    height: auto;
    margin-bottom: 31px;
    position: relative;

    &:focus-within {
      border: 5px solid #e1ebfb;

      textarea,
      input {
        border-radius: 2.81px;
        border: 2px solid #256be4;
      }
    }

    input,
    textarea {
      width: 100% !important;
      border-radius: 6.81px;
      background: var(--neutrals-0);
      border: 2px solid rgba(0, 0, 0, 0);
      padding: 13px 18px;
      caret-color: var(--royal-800);
    }

    input {
      height: 40px;
    }
  }

  .checkbox-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 6.81px;

    input[type='checkbox'] {
      margin-right: 22px;
      height: 18px;
      width: 18px;
      background: transparent;
      border-radius: 50%;
      vertical-align: middle;
      border: 1px solid #ddd;
      appearance: none;
      -webkit-appearance: none;
      outline: 1px solid var(--neutrals-0);
      cursor: pointer;
      position: relative;

      &:checked {
        &::after {
          content: '';
          display: inline-block;
          width: 9px;
          height: 9px;
          border-radius: 9px;
          background-color: #0071ff;
          top: 50%;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
        }
      }
    }

    .checkbox-text {
      font-family: paralucent-text, sans-serif;
      font-weight: 300;
      font-size: 20px;
      line-height: 150%;
      color: var(--neutrals-0);
      cursor: pointer;

      a {
        color: var(--neutrals-0);
        width: fit-content;
        display: inline-block;
      }
    }
  }

  .input-wrapper,
  .checkbox-wrapper {
    position: relative;

    .error-message {
      position: absolute;
      bottom: -25px;
      font-family: paralucent-text, sans-serif;
      font-weight: 300;
      font-size: 12px;
      line-height: 150%;
      color: var(--red);
    }

    img.valid-checkmark {
      position: absolute;
      right: 13px;
      width: 30px;
      height: 30px;
    }
  }

  ::placeholder {
    font-family: paralucent-text, sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    color: var(--neutrals-500);
  }

  button {
    margin-left: auto !important;
  }

  @include breakpoint(medium_devices) {
    button {
      margin: 0 !important;
      width: 75% !important;
    }

    .form-thank-you {
      margin-top: 46px;
    }
  }
}
