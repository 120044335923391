@import 'src/styles/mixins';
.legal-container {
  padding: 60px 20% 0;

  @include breakpoint(medium_devices) {
    padding: 60px 10% 0;
  }
}

.legal {
  background: var(--white);
  color: black;

  .legal-wrapper {
    padding: 0;
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    word-break: break-all;
  }

  .legal-header {
    display: flex;
    flex-direction: column;
    padding: 6vh 0 1vh 0;
    border-bottom: 1px solid #c7c7c7;
    margin-bottom: 3vh;
  }

  h5 {
    padding-top: 20px;
  }

  .address-andinfo {
    display: flex;
    flex-flow: column;

    @include breakpoint(medium_devices) {
      flex-flow: column;
    }

    .address-column {
      padding-right: 30px;
      width: 100%;
      margin: 10px;
    }

    .address-row {
      margin-bottom: 3vh;
    }
  }

  .legal-content-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 4vh;
    border-bottom: 1px solid #c7c7c7;
    margin-bottom: 4vh;

    &.last {
      margin-bottom: 0;
    }
  }
}
