@import 'src/styles/mixins';

.title {
  margin-bottom: 90px;
}
.card {
  display: flex;
  align-items: center;
  padding: 6%;
  background: rgb(38, 68, 174);
  background: linear-gradient(90deg, rgba(38, 68, 174, 1) 0%, rgba(8, 16, 66, 1) 100%);
}

.shaping-card {
  @include breakpoint(medium_devices) {
    .post-title {
      h2.capitalised {
        margin-bottom: 30px;
      }
    }
    .card.paragraph-2 {
      padding: 10%;
    }
  }
  @include breakpoint(large_devices) {
    display: flex;
    flex-flow: row;
    align-items: center;
    .post-title {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 35%;
    }
  }
}
.basic-card-wrapper {
  display: flex;
  margin-bottom: 15vh;
  width: 100%;
}

.basics-card {
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  flex: 1 1 0px;
  border: 15px solid transparent;
  padding: 20px;
  @include breakpoint(medium_devices) {
    min-height: 460px;
  }
  @include breakpoint(large_devices) {
    padding: 50px;
  }
  .card-title {
    margin-bottom: 25px;
  }
  .card-content {
    margin-bottom: 25px;
  }
  .card-icon {
    width: 83px;
    align-self: end;
    margin-top: auto;
  }
}
.about-hero {
  color: var(--black);
  background-color: var(--white);
  .about-header {
    @include breakpoint(medium_devices) {
      display: none;
    }
  }
  .hero-content-wrapper {
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 65px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: clip;
    @include breakpoint(medium_devices) {
      padding-top: 0;
    }
    @include breakpoint(large_devices) {
      min-height: 100vh;
    }
    .about-content {
      flex: 0.8 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include breakpoint(medium_devices) {
        padding: 0 5%;
      }
      .about-title {
        letter-spacing: 1px;
        z-index: 1;
        span {
          color: var(--royal-800);
        }
      }
    }
    .hero-subheading {
      max-width: 550px;
      text-align: justify;
      margin-top: 53px;
    }
    .herobg {
      max-width: 100%;
      position: absolute;
      z-index: 0;
      @include breakpoint(medium_devices) {
        right: -100px;
      }
      bottom: -180px;
      @include breakpoint(large_devices) {
        bottom: -100px;
        right: -150px;
      }
      @include breakpoint(wide_devices) {
        width: 800px;
        right: -800px;
        bottom: -275px;
      }
    }
  }
}
