@mixin breakpoint($point) {
  @if $point == extra_small_devices {
    @media only screen and (max-width: 376px) {
      @content;
    }
  } @else if $point == small_devices {
    @media only screen and (max-width: 600px) {
      @content;
    }
  } @else if $point == medium_devices {
    @media only screen and (max-width: 768px) {
      @content;
    }
  } @else if $point == large_devices {
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == tablets {
    @media only screen and (min-width: 768px) and (max-width: 1100px) {
      @content;
    }
  } @else if $point == extra_large_devices {
    @media only screen and (min-width: 1500px) {
      @content;
    }
  } @else if $point == full_hd {
    @media only screen and (min-width: 1919px) {
      @content;
    }
  } @else if $point == wide_devices {
    @media only screen and (min-aspect-ratio: 16/9) and (min-width: 2440px) {
      @content;
    }
  } @else if $point == ultrawide_devices {
    @media only screen and (min-aspect-ratio: 21/9) {
      @content;
    }
  }
}
