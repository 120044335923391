@import 'src/styles/mixins.scss';

@include breakpoint(medium_devices) {
  .fixed-element {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
  }

  .footer-container.footer-container {
    margin: auto auto 10px auto;
    transition: transform 0.3s;
  }
}

.footer-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;

  @include breakpoint(medium_devices) {
    bottom: 0;
    position: relative;

    &.with-links {
      display: flex;
      flex-direction: column;

      .box {
        flex-direction: column;

        .logo-and-copyright-text-wrapper {
          flex-direction: column;
          align-items: flex-start;

          .logo {
            margin-bottom: 15px;
          }

          .copyright-text {
            margin: 0 0 15px 0;
          }
        }

        .right-part {
          margin: 0;
          justify-content: flex-start;

          /*.footer-menu {
            > span:first-of-type {
              margin-left: 0;
            }
          }*/
        }
      }
    }
  }

  .footer-icons-wrapper {
    position: relative;

    .right-part-text {
      padding-top: 10px;

      > div {
        color: var(--white);
      }
    }
  }

  &.additional-padding-bottom-26 {
    padding-bottom: 26px;
  }

  .only-text-footer-content {
    width: 100%;
  }

  .footer-content {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;

    &.no-padding {
      padding: 0;
    }

    .separator {
      width: 100%;
      height: 1px;
      background-color: var(--white);
      z-index: 2;
      margin-bottom: 32px;

      @include breakpoint(medium_devices) {
        margin-bottom: 24px;

        &.smaller-margin {
          margin-bottom: 12px !important;
        }
      }
    }

    .box {
      display: flex;
      overflow: hidden;
      position: relative;
      align-self: stretch;
      box-sizing: border-box;
      align-items: center;
      flex-shrink: 0;
      border-color: transparent;
      margin-right: 0;
      margin-bottom: 0;
      justify-content: space-between;
    }

    .logo {
      width: 135px;
      height: 40px;
      position: relative;

      @include breakpoint(medium_devices) {
        height: 35px;
        width: auto;
      }
    }

    .logo-and-copyright-text-wrapper {
      display: flex;
      align-items: center;

      .copyright-text {
        margin-left: 34px;
      }
    }

    .right-part {
      display: flex;
      align-content: center;
      justify-content: flex-end;

      .footer-menu {
        display: flex;
        @include breakpoint(medium_devices) {
          flex-direction: column;
          width: 100%;
        }
      }

      @include breakpoint(medium_devices) {
        margin-left: 40px;
        width: 100%;

        a {
          width: 100% !important;
        }

        button {
          min-width: unset !important;
          width: 100% !important;
        }
      }

      a {
        display: flex;
      }

      .footer-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 13px;
        padding-top: 3px;
        float: left;

        .footer-icons-item {
          height: 40px;
          width: 40px;
          border-radius: 4px;
          object-fit: cover;

          &:first-of-type {
            margin-right: 8px;
          }
        }

        @include breakpoint(medium_devices) {
          display: none;
        }
      }

      .right-part-text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.with-margin-right {
          margin-right: 20px;
        }

        @include breakpoint(medium_devices) {
          display: none;
        }
      }

      .footer-menu {
        a {
          color: var(--white);
        }
        > span {
          @include breakpoint(large_devices) {
            margin-left: 24px;
          }
        }
      }
    }
  }
}

.homepage-footer {
  position: fixed;
  padding: 0 5%;
  z-index: 10;

  @include breakpoint(medium_devices) {
    padding: 0 5%;
  }
}

.endpage-footer {
  position: absolute !important;
  bottom: 0;
  z-index: 2;
}
@include breakpoint(tablets) {
  .footer-container {
    padding: 0 5%;
  }
}
