@import 'src/styles/mixins';

@include breakpoint(medium_devices) {
  .our-services.mesh-blue {
    padding: 5%;
  }
  .our-approach-section {
    padding-top: 15vh;
  }
  .approach-left-item {
    padding-right: 15px;
  }
}
@include breakpoint(tablets) {
  .services-header {
    display: none;
  }
  .services-hero {
    padding-top: 10vh;
  }
  .services-wrapper {
    width: 100%;
    padding: 0 5%;
  }

  .services-page .alice-carousel .services-card {
    height: 299px !important;
    width: 100%;
  }

  .services-page .services-wrapper {
    min-height: 1000px;
  }

  .services-page .services-observed.services-in-view ~ .our-services-content {
    bottom: 0;
    width: 100%;
    position: relative;
  }

  .projects-and-team-content.fixed-container.our-approach-section {
    padding-top: 10%;
  }
  .services-page .services-bottom {
    display: none !important;
  }
}
