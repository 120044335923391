@import 'src/styles/mixins';

h5 span {
  font-weight: 500;
  letter-spacing: 1px;
}
@include breakpoint(large_devices) {
  .about-hero {
    z-index: 2;
    display: block;
    position: relative;
  }
  .ant-tabs-tab-btn .paragraph-3 strong {
    letter-spacing: 1px;
    font-weight: 00;
  }
  // PARALAX VALUES
  .about-page {
    .livorit-solutions {
      height: 2900px;
      background: white;
    }
  }
  .about-observed {
    position: absolute;
    bottom: 0px;
  }
  .livorit-solutions {
    position: relative;
    display: block;
  }
  .about-observed.about-in-view ~ .projects-and-team-content {
    position: fixed;
    height: 2900px;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .what-makes-us-special-content.our-values {
    position: relative;
    padding-bottom: 185px;
  }

  //PARALLAX BASICS
  .basics-wrapper {
    position: relative;
    min-height: 1750px;
  }
  .basics-bottom {
    position: absolute;
    bottom: 0;
  }
  .basics-top {
    position: absolute;
    top: 0;
  }
  .basics-content {
    position: relative;
    display: block;
    padding-bottom: 100px;
  }
  .basics-observed.basics-in-view ~ .basics-content {
    position: fixed;
    width: 100%;
    z-index: 1;
    padding-bottom: 0 !important;
    //height: 1650px;
  }
  .basics-observed.basics-top.basics-in-view ~ .basics-content {
    top: 0;
  }
  .basics-observed.basics-bottom.basics-in-view ~ .basics-content {
    bottom: 400px;
  }
  .basics-content.mesh-blue {
    //height: 1650px;
    padding: 0;
    padding-top: 15vh !important;
  }
}

.mesh-blue {
  /*background-color: hsla(233, 0%, 0%, 1);
  background-image: radial-gradient(at 27% 19%, hsla(234, 94%, 13%, 1) 0px, transparent 50%),
    radial-gradient(at 59% 61%, hsla(217, 100%, 20%, 1) 0px, transparent 50%),
    radial-gradient(at 10% 50%, hsla(242, 100%, 62%, 1) 0px, transparent 50%),
    radial-gradient(at 82% 42%, hsla(234, 100%, 66%, 1) 0px, transparent 50%),
    radial-gradient(at 37% 74%, hsla(220, 100%, 62%, 1) 0px, transparent 50%),
    radial-gradient(at 60% 22%, hsla(240, 100%, 57%, 1) 0px, transparent 50%),
    radial-gradient(at 91% 94%, hsla(242, 100%, 70%, 1) 0px, transparent 50%),
    radial-gradient(at 8% 61%, hsla(271, 93%, 31%, 1) 0px, transparent 50%),
    radial-gradient(at 81% 72%, hsla(205, 100%, 57%, 0.29) 0px, transparent 50%);*/
  padding: 15vh 5%;
  @include breakpoint(large_devices) {
    padding: 15vh 0;
  }
}

@include breakpoint(tablets) {
  .about-page .livorit-solutions {
    height: 2200px;
  }
  .about-observed.about-in-view ~ .projects-and-team-content {
    height: 2200px;
  }
  .hero-content-wrapper {
    padding: 5%;
  }

  .basics-content.mesh-blue {
    padding: 5%;
  }
  .our-way-ico-wrapper {
    margin-top: 150px !important;
  }
  .approach-observed {
    display: none;
  }
  .our-way .our-way-ico-wrapper .our-way-content .our-way-content-element {
    padding: 5px;
  }
}
