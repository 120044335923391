@import 'src/styles/mixins';

.clippy-zone.transparent-blue.snap-create-point {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;

  @include breakpoint(large_devices) {
    .fixed-element {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
    }
  }
}

@include breakpoint(medium_devices) {
  .to-services {
    margin-bottom: 90px;
    margin-top: 25px !important;
  }
}

/* Observer What you can expect from us */

.what-you-can-expect-from-us {
  padding-top: 5vh;
  margin-top: -70px;
  position: relative;
  .user-experience-text {
    margin-top: 30px;
  }
  .gradient-edge {
    position: absolute;
    top: -170px;
    width: 100vw;
    @include breakpoint(large_devices) {
      background: rgb(2, 8, 69);
      background: linear-gradient(0deg, rgba(2, 8, 69, 1) 0%, rgba(2, 8, 69, 1) 54%, rgba(2, 8, 69, 0) 90%);
    }
    @include breakpoint(tablets) {
      top: -60px;
    }
  }

  @media only screen and (max-width: 768px) {
    margin: 0;

    .gradient-edge {
      display: none;
    }
  }
}

span.observed.in-clippy {
  position: absolute;
  width: 100%;
  max-width: 1250px;
  bottom: 0;
  @include breakpoint(tablets) {
    max-width: 100%;
    width: 90%;
  }
}

span.observed.in-clippy.in-view ~ .fixed-clippy-element {
  position: fixed;
  max-width: 1250px;
  @include breakpoint(tablets) {
    max-width: 100%;
    width: 90%;
  }
}

.fixed-clippy-element {
  max-width: 1250px;
  bottom: 100px;
  position: absolute;

  @include breakpoint(medium_devices) {
    position: relative !important;
    bottom: unset !important;
  }

  @include breakpoint(extra_large_devices) {
    bottom: 420px;
  }
  @include breakpoint(tablets) {
    max-width: 100%;
    width: 90%;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;

  &.larger-height {
    min-height: 1500px;

    @include breakpoint(medium_devices) {
      min-height: 100vh;
    }
    @include breakpoint(tablets) {
      min-height: 1300px;
    }
  }
}

/* Observer What you can expect from us END */

.what-you-can-expect-from-us-content {
  overflow: unset !important;

  .what-you-can-expect-from-us-content-text {
    margin-bottom: 10vh;
  }

  .what-you-can-expect-from-us-cards-desktop {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 27px;

    .card-item {
      display: flex;
      flex-direction: column;
      width: 300px;
      height: 231px;
      background: rgba(255, 255, 255, 0.12);
      padding: 30px;

      .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.5s;
        transform-style: preserve-3d;

        .flip-card-front,
        .flip-card-back {
          position: absolute;
          height: 100%;
          width: 100%;
          background: transparent;
          backface-visibility: hidden;
        }

        .flip-card-front {
          display: flex;
          flex-direction: column;
          opacity: 1;
          transition: opacity 0.05s;

          img {
            max-width: 58px;
            max-height: 52px;
            height: 52px;
            margin-bottom: 35px;
          }

          div {
            width: 100%;
            text-align: left;
            color: var(--white);
          }
        }

        .flip-card-back {
          display: flex;
          flex-direction: column;
          transform: rotateY(180deg);
          opacity: 0;
          transition: opacity 0.05s;

          ul {
            margin: 0;
            padding-inline-start: 20px;

            li {
              list-style: disc !important;
            }
          }
        }
      }

      &:hover .flip-card-inner {
        transform: rotateY(180deg);
        transition: transform 0.5s;

        .flip-card-front {
          opacity: 0;
          transition: opacity 0.05s;
        }

        .flip-card-back {
          opacity: 1;
          transition: opacity 0.05s;
        }
      }
    }
  }

  .what-you-can-expect-from-us-cards-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 22px;

    .card-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background: rgba(255, 255, 255, 0.07);
      padding: 24px;

      .card-item-content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          max-width: 48px;
          max-height: 52px;
          height: 52px;
          margin-right: 24px;
          @include breakpoint(medium_devices) {
            max-width: 36px;
          }
        }

        div {
          width: 100%;
          text-align: left;
          color: var(--white);
          font-size: 22px !important;
          font-weight: 500 !important;
          line-height: 130%;
          font-family: paralucent, sans-serif !important;
        }

        .chevron-icon {
          width: 24px;
          height: 12px;
          margin: 0 0 0 24px;
          transition: transform 0.5s;

          &.expanded {
            transform: rotateX(180deg);
            transition: transform 0.5s;
          }
        }
      }

      .card-item-content-expanded {
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.25s ease-out;

        ul {
          margin: 0;
          padding: 32px 0 0 0;
          padding-inline-start: 20px;

          li {
            list-style: disc !important;
          }
        }

        &.show {
          max-height: 500px;
          transition: max-height 0.25s ease-in;
        }
      }
    }
  }

  /* Animation */

  .what-you-can-expect-from-us-cards-desktop {
    .card-item {
      transition: transform 0.3s;

      .icon-wrapper img {
        transition: transform 0.4s;
      }

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;

        .icon-wrapper img {
          transform: scale(1.2);
          transition: transform 0.4s;
        }
      }
    }
  }

  @include breakpoint(medium_devices) {
    padding: 0 5% 75px 5%;
  }
}
