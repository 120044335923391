@import url(react-alice-carousel/lib/alice-carousel.css);
@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
:root {
  --accent: #7ba5dd;
  --blue: #020845;
  --light-primary: #171c8f;
  --tag-primary: #00000052;
  --red: red;
  --carmine-900: #cf1314;
  --royal-100: #e1ebfb;
  --royal-800: #256be4;
  --white: #fefefe;
  --white-700: rgba(255, 255, 255, 0.8);
  --black: #000000;
  --neutrals-0: #ffffff;
  --neutrals-50: #fefefe;
  --neutrals-100: #f7f7f7;
  --neutrals-200: #eeeeef;
  --neutrals-300: #d2d4d8;
  --neutrals-500: #8a909a;
  --neutrals-800: #1e212a;
  --neutrals-900: #060709;
  --neutrals-700: #404653; }

.observed.in-view + .framer .fixed-element {
  position: fixed !important;
  top: 10px;
  transition: transform 0.2s; }

html body #root .refferenced-div .in-view ~ .BackgroundVideo.undefined {
  position: fixed !important;
  top: 10px !important;
  transition: transform 0.2s; }

/* paralucent font: */
@font-face {
  font-family: 'paralucent', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'paralucent-text', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

/* MonetaSans font: */
@font-face {
  font-family: 'MonetaSans-Black';
  src: url("~src/assets/fonts/moneta-sans/MonetaSans-Black.eot");
  src: url("~src/assets/fonts/moneta-sans/MonetaSans-Black.eot?#iefix") format("embedded-opentype"), url("~src/assets/fonts/moneta-sans/MonetaSans-Black.woff2") format("woff2"), url("~src/assets/fonts/moneta-sans/MonetaSans-Black.woff") format("woff"), url("~src/assets/fonts/moneta-sans/MonetaSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'MonetaSans-Regular';
  src: url("~src/assets/fonts/moneta-sans/MonetaSans-Regular.eot");
  src: url("~src/assets/fonts/moneta-sans/MonetaSans-Regular.eot?#iefix") format("embedded-opentype"), url("~src/assets/fonts/moneta-sans/MonetaSans-Regular.woff2") format("woff2"), url("~src/assets/fonts/moneta-sans/MonetaSans-Regular.woff") format("woff"), url("~src/assets/fonts/moneta-sans/MonetaSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'MonetaSans-Light';
  src: url("~src/assets/fonts/moneta-sans/MonetaSans-Light.eot");
  src: url("~src/assets/fonts/moneta-sans/MonetaSans-Light.eot?#iefix") format("embedded-opentype"), url("~src/assets/fonts/moneta-sans/MonetaSans-Light.woff2") format("woff2"), url("~src/assets/fonts/moneta-sans/MonetaSans-Light.woff") format("woff"), url("~src/assets/fonts/moneta-sans/MonetaSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'MonetaSans-Bold';
  src: url("~src/assets/fonts/moneta-sans/MonetaSans-Bold.eot");
  src: url("~src/assets/fonts/moneta-sans/MonetaSans-Bold.eot?#iefix") format("embedded-opentype"), url("~src/assets/fonts/moneta-sans/MonetaSans-Bold.woff2") format("woff2"), url("~src/assets/fonts/moneta-sans/MonetaSans-Bold.woff") format("woff"), url("~src/assets/fonts/moneta-sans/MonetaSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

h1 {
  font-family: MonetaSans-Black, sans-serif !important;
  font-size: 124px;
  line-height: 100%;
  font-weight: 900; }
  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 62px; } }
  @media only screen and (max-width: 376px) {
    h1 {
      font-size: 45px; } }

h2 {
  font-family: MonetaSans-Black, sans-serif !important;
  font-size: 76px;
  line-height: 100%;
  font-weight: 900; }
  @media only screen and (max-width: 600px) {
    h2 {
      font-size: 39px; } }
  @media only screen and (max-width: 376px) {
    h2 {
      font-size: 32px; } }

h3 {
  font-family: paralucent, sans-serif !important;
  font-weight: 500;
  font-size: 51.58px;
  line-height: 61.9px; }
  @media only screen and (max-width: 600px) {
    h3 {
      font-size: 26px;
      line-height: 31.2px; } }

h4 {
  font-family: paralucent, sans-serif !important;
  font-weight: 500;
  font-size: 31px;
  line-height: 46.5px; }

h5 {
  font-family: paralucent, sans-serif !important;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%; }
  @media only screen and (max-width: 600px) {
    h5 {
      font-size: 20px; } }

.paragraph-1 {
  font-family: paralucent-text, sans-serif !important;
  font-weight: 500;
  font-size: 30px; }
  @media only screen and (max-width: 600px) {
    .paragraph-1 {
      font-size: 17px; } }
  @media only screen and (max-width: 376px) {
    .paragraph-1 {
      font-size: 19px; } }

.paragraph-2 {
  font-family: paralucent-text, sans-serif !important;
  font-weight: 300;
  font-size: 24px;
  line-height: 150%; }
  @media only screen and (max-width: 600px) {
    .paragraph-2 {
      font-size: 22px; } }
  @media only screen and (max-width: 376px) {
    .paragraph-2 {
      font-size: 14px; } }

.paragraph-3 {
  font-family: paralucent-text, sans-serif !important;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%; }
  @media only screen and (max-width: 600px) {
    .paragraph-3 {
      font-size: 16px; } }

.paragraph-4 {
  font-family: paralucent-text, sans-serif !important;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%; }

.paragraph-5 {
  font-family: paralucent, sans-serif !important;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%; }
  @media only screen and (max-width: 768px) {
    .paragraph-5 {
      letter-spacing: 1px; } }

.paragraph-6 {
  font-family: paralucent-text, sans-serif !important;
  font-weight: 300;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 1px; }
  @media only screen and (max-width: 768px) {
    .paragraph-6 {
      font-size: 16px; } }

:root {
  --accent: #7ba5dd;
  --blue: #020845;
  --light-primary: #171c8f;
  --tag-primary: #00000052;
  --red: red;
  --carmine-900: #cf1314;
  --royal-100: #e1ebfb;
  --royal-800: #256be4;
  --white: #fefefe;
  --white-700: rgba(255, 255, 255, 0.8);
  --black: #000000;
  --neutrals-0: #ffffff;
  --neutrals-50: #fefefe;
  --neutrals-100: #f7f7f7;
  --neutrals-200: #eeeeef;
  --neutrals-300: #d2d4d8;
  --neutrals-500: #8a909a;
  --neutrals-800: #1e212a;
  --neutrals-900: #060709;
  --neutrals-700: #404653; }

html body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  html body *,
  html body *::before,
  html body *::after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
  html body h1,
  html body h2,
  html body h3,
  html body h4,
  html body h5,
  html body h6,
  html body p {
    margin: 0;
    padding: 0; }
  html body a {
    text-decoration: none;
    outline: none; }
  html body #root {
    --animate-delay: 0.3s;
    background-color: var(--blue);
    color: var(--white);
    font-family: paralucent-text, sans-serif;
    max-width: 100vw;
    overflow: hidden; }
    html body #root .container {
      position: relative; }
    html body #root .flexbox {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      min-height: calc(100vh);
      overflow: hidden;
      width: 100%;
      max-width: 1250px;
      margin: 0 auto; }
    html body #root .gradient-blue {
      background: #050f7a;
      background: -moz-linear-gradient(142deg, #050f7a 0%, #080a23 90%);
      background: -webkit-linear-gradient(142deg, #050f7a 0%, #080a23 90%);
      background: linear-gradient(142deg, #050f7a 0%, #080a23 90%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020977",endColorstr="#080A23",GradientType=1); }
    html body #root .transparent-blue {
      background: #020845;
      background: linear-gradient(0deg, #020845 0%, #020845 90%, rgba(2, 8, 69, 0) 100%); }
    html body #root .solid-blue {
      background: #020845; }
    html body #root .flex-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      align-content: stretch; }
      html body #root .flex-container .flex-items {
        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: auto;
        order: 0; }
    html body #root .flex-full-width {
      align-self: stretch; }
    html body #root .BackgroundVideo {
      overflow: hidden; }
      html body #root .BackgroundVideo video {
        object-fit: cover !important;
        width: 100% !important;
        height: 100% !important;
        top: 0 !important;
        left: 0 !important; }

a {
  color: var(--royal-800);
  transition: 0.3s ease-in-out; }
  a:hover, a:focus {
    color: var(--royal-800) !important; }

ul,
ol {
  list-style: square !important; }
  @media only screen and (max-width: 376px) {
    ul,
    ol {
      list-style: none !important;
      margin: 0 !important; } }

.divider {
  height: 1px;
  width: 76.25px;
  background: var(--neutrals-300); }
  .divider.mobile {
    display: none; }
  @media only screen and (max-width: 768px) {
    .divider {
      width: 86.4px; }
      .divider.mobile {
        display: block; }
      .divider.desktop {
        display: none; } }

.clippy-zone {
  clip-path: polygon(0px 100%, 100% 100%, 100% 0%, 0px 0%); }

.align-left {
  text-align: left; }

.centered {
  text-align: center; }

.align-right {
  text-align: right; }

.capitalised {
  text-transform: uppercase; }

.mix-blend-screen {
  mix-blend-mode: inherit; }

.framer {
  z-index: 1;
  position: relative; }

.observed {
  z-index: 1;
  position: relative; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
div {
  opacity: 1;
  visibility: visible;
  /* Old IE */
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out; }

.wf-loading h1,
.wf-loading h2,
.wf-loading h3,
.wf-loading h4,
.wf-loading h5,
.wf-loading h6,
.wf-loading p,
.wf-loading li,
.wf-loading a,
.wf-loading div {
  opacity: 0; }

.ie.wf-loading h1,
.ie.wf-loading h2,
.ie.wf-loading h3,
.ie.wf-loading p,
.ie.wf-loading li,
.ie.wf-loading a,
.ie.wf-loading div {
  visibility: hidden;
  /* Old IE */ }

@media only screen and (max-width: 768px) {
  .CookieConsent {
    bottom: 0;
    top: unset !important;
    padding: 30px 30px;
    text-align: center; }
    .CookieConsent button {
      margin: 15px !important;
      min-width: auto !important;
      left: 0; }
    .CookieConsent.alice-carousel__stage-item {
      line-height: 30px !important; } }
