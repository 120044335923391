@import 'src/styles/mixins';

.header-menu-animated {
  position: fixed;
  top: 0;
  visibility: hidden;
  .menu-items {
    width: 100%;
    flex: 0 0 auto;
    padding-top: 15vh;
    margin-bottom: 50px !important;
    h2 a {
      color: #012584;
    }
  }
  .menu-item {
    margin-bottom: 35px;
  }
  a.margin-bot-35 {
    margin-bottom: 35px;
  }
  .paragraph-3.menu-bottom-element {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    @include breakpoint(medium_devices) {
      text-align: left !important;
    }
  }
  #menu-wrapper {
    width: 100vw;
    background: #fff;
    height: 100vh;
    position: relative;
    overflow: hidden !important;
    padding: 0 5%;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    div {
      color: var(--neutrals-800);
    }

    @include breakpoint(medium_devices) {
      .menu-bottom-element {
        text-align: right;
      }
    }

    @include breakpoint(wide_devices) {
      .menu-footer-content,
      .menu-content {
        max-width: 1250px;
        margin: 0 auto;
      }
    }

    .menu-content {
      display: flex;
      height: 85vh;
      max-width: 1250px;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      @include breakpoint(medium_devices) {
        padding: 20px;
      }
    }

    .menu-footer-container {
      width: 100%;
      position: relative;
      @include breakpoint(medium_devices) {
        padding: 0 5%;
      }

      .menu-footer-content {
        .separator-dark {
          width: 100%;
          height: 1px;
          background-color: black;
          z-index: 4;
          margin-top: 60px;
          display: block;

          @include breakpoint(medium_devices) {
            width: 90%;
            margin-right: auto;
          }
        }

        .box {
          display: flex;
          overflow: hidden;
          position: relative;
          align-self: stretch;
          box-sizing: border-box;
          align-items: center;
          flex-shrink: 0;
          border-color: transparent;
          margin-right: 0;
          margin-bottom: 0;
          justify-content: space-between;

          .logo {
            width: 135px;
            height: 40px;
            position: relative;

            @include breakpoint(medium_devices) {
              display: none;
            }
          }

          .text {
            text-align: right;
            line-height: 100%;
            padding: 20px 0;
            font-family: 'paralucent', sans-serif;
            font-weight: 400;

            span {
              clear: both;
              display: block;
            }

            @include breakpoint(medium_devices) {
              text-align: left;
            }
          }
        }
      }
    }
  }

  .menu-icon-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: unset;

    &.force-zindex {
      z-index: 10 !important;
    }

    .menu-icon-wrapper {
      position: absolute;
      transition: transform 0.2s;

      &:hover {
        transform: scale(1.2) !important;
      }

      .animating-menu-icon {
        width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;

        .part1 {
          position: absolute;
          width: 36px;
          height: 4px;
          background: #012584;
          transition: color ease-in-out 0.18s, transform ease-in-out 0.18s;
        }

        .part2 {
          position: absolute;
          width: 36px;
          height: 4px;
          background: #012584;
          transition: color ease-in-out 0.18s, transform ease-in-out 0.18s;
          transform: rotate(90deg);
        }

        .part3 {
          position: absolute;
          width: 4px;
          height: 4px;
          background: #012584;
          transition: color ease-in-out 0.18s;
          border-radius: 9999px;
        }
      }
    }
  }
}
